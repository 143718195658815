<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">

        </el-row>
        <RevenueStatistics></RevenueStatistics>
      </div>
    </PageHeaderLayout>

  </div>
</template>

<script>
import RevenueStatistics from "./RevenueStatistics";

export default {
  name: "RevenueDataIndex",
  components: { RevenueStatistics },
}
</script>

<style scoped>

</style>

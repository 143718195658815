<template>
  <div style="padding-top: 5px">
    <RevenueChart ref="rChart"></RevenueChart>
  </div>
</template>

<script>

import RevenueChart from "@/pages/dashboard/revenue/RevenueChart";

export default {
  name: "RevenueStatistics",
  components: { RevenueChart},
  mounted() {
    // this.$refs['rChart'].init()
  },
}
</script>

<style scoped>

</style>
